import React from 'react';
import { SEO, Layout, Header, Footer, InfoSection, SinglePilot } from '../../components';

export default ({ pageContext: ctx }) => {
  const { heroSection, infoSection, team, metadata, translationPath, i18n_lang: lang } = ctx;

  return (
    <Layout>
      <SEO {...metadata.metadata} />
      <Header {...heroSection} lang={lang} translationPath={translationPath} />

      <InfoSection {...infoSection} lang={lang} />

      {team &&
        team.map(teamMember => <SinglePilot key={teamMember.name} {...teamMember} lang={lang} />)}

      <Footer lang={lang} />
    </Layout>
  );
};
